import { Controller as BaseController } from "@hotwired/stimulus"

export class Controller extends BaseController {
  static get targets() {
    return [ "modal", "notFound" ]
  }

  toggleModal(target) {
    switch (target) {
      case 'notFound':
        this.notFoundTarget.classList.toggle('hidden');
        break;
      }
    this.modalTarget.classList.toggle('hidden');
    document.body.classList.add("h-full");
    document.body.classList.add("overflow-hidden");
  }

  async submitNotFound(event) {
    event.preventDefault();

    var formData = new FormData(event.target);
    var params = {};
    formData.forEach((value, key) => params[key] = value);

    await fetch(this.notFoundRequest(params)).then(async (response) => {
      const res = await response.json();

      if (res.error) {
      } else {
        this.closeModal();
      }
    });
  }

  closeModal() {
    this.modalTarget.classList.add('hidden');
    this.notFoundTarget.classList.add('hidden');
    document.body.classList.remove("h-full");
    document.body.classList.remove("overflow-hidden");
  }

  clickOutside(e) {
    if(!this.modalTarget.classList.contains("hidden")) {
      this.closeModal()
    }
  }

  notFoundRequest(params) {
    return new Request(
      '/api/landing/user-code',
      {
        body: JSON.stringify(params),
        method: 'POST',
        headers: { "Content-Type": "application/json" },
      }
    );
  }
}
