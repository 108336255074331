
import { Controller as BaseController } from "@hotwired/stimulus"

export class Controller extends BaseController {
  static get targets() {
    return [ "trackingButton" ]
  }

  openUrl(e) {
    e.preventDefault();
    window.open(this.trackingButtonTarget.dataset.url, '_blank');
  }
}