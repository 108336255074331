import { Controller as BaseController } from "@hotwired/stimulus"

export class Controller extends BaseController {
  static get targets() {
    return [ "accountDropdown", "accountMenuItems", "caret" ]
  }

  toggleAccountMenu() {
    this.accountMenuItemsTarget.classList.toggle('hidden');
    this.caretTarget.classList.toggle('rotate-90');
  }

  toggleAccountDropdown() {
    this.accountDropdownTarget.classList.toggle('hidden');
    this.accountDropdownTarget.classList.toggle('flex');
  }

  closeDropdown() {
    this.accountDropdownTarget.classList.add('hidden');
    this.accountDropdownTarget.classList.remove('flex');
  }

  clickOutside(e) {
    if(this.hasAccountDropdownTarget) {
      if(this.element === e.target || this.element.contains(e.target)) return;
      this.closeDropdown()
    }
  }
}
