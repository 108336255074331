import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = [
    "rateRadioButton," +
    "dayRadioButton",
    "slotSelect",
    "daySelectionZone",
  ];

  static values = {
    rateId: Number,
    date: String
  }

  rateRadioButtonTargetConnected(element) {
    if (element.checked) {
      this.rateId = element.value;
      this.updateDays(this.rateId, this.daySelectionZoneTarget.id);
    }
  }

  dayRadioButtonTargetConnected(element) {
      if (element.checked) {
        this.date = element.value;
        this.updateSlots(this.slotSelectTarget.id)
      }
  }

  selectedRate(event) {
    this.rateId = event.currentTarget.value;
    let daySelectionZone = this.daySelectionZoneTarget.id;

    this.updateDays(this.rateId, daySelectionZone);
  }

  selectedDeliveryDay(event) {
    let target = this.slotSelectTarget.id;
    this.date = event.currentTarget.value;

    this.updateSlots(target);
  }

  updateDays(rateId, target) {
    get(`${window.location}/delivery/available_days?rate_id=${rateId}&target=${target}`, {
      responseKind: "turbo-stream",
    })
  }

  updateSlots(target) {
    get(`${window.location}/delivery/slots?date=${this.date}&target=${target}&rate_id=${this.rateId}`, {
      responseKind: "turbo-stream",
    })
  }
}
