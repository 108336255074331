import { Controller as BaseController } from "@hotwired/stimulus"

export class Controller extends BaseController {
  static get targets() {
    return [ "description", "seeMoreButton", "seeMoreButtonContent" ]
  }

  connect() {
    if (this.descriptionTarget.offsetHeight < this.descriptionTarget.scrollHeight) {
      this.seeMoreButtonTarget.classList.remove("hidden")
      this.seeMoreButtonTarget.classList.add("flex")
    }
  }

  seeMore() {
    this.descriptionTarget.classList.toggle("max-h-[52px]")
    this.descriptionTarget.classList.toggle("max-h-full")
    this.descriptionTarget.classList.toggle("overflow-hidden")
    this.seeMoreButtonTarget.classList.toggle("bottom-0")
    this.seeMoreButtonTarget.classList.toggle("-bottom-4")
    this.seeMoreButtonContentTarget.nextElementSibling.classList.toggle("-rotate-90")
    this.seeMoreButtonTarget.firstElementChild.classList.toggle("hidden")
    this.seeMoreButtonTarget.classList.toggle("lg:w-1/4")
    if (this.seeMoreButtonContentTarget.innerText === "En voir plus") {
      this.seeMoreButtonContentTarget.innerText = "En voir moins"
    } else {
      this.seeMoreButtonContentTarget.innerText = "En voir plus"
    }
  }
}