import { Controller as BaseController } from "@hotwired/stimulus"

export class Controller extends BaseController {
  static get targets() {
    return [ "menuItem", "arrow" ];
  }

  toggleSideBarMenuItem() {
    if (this.hasMenuItemTarget) {
      this.menuItemTarget.classList.toggle('hidden');
      this.arrowTarget.classList.toggle('rotate-90');
    }
  }
}
