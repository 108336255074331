import { Controller as BaseController } from "@hotwired/stimulus";
let debounce = require("lodash/debounce");

export class Controller extends BaseController {
  static get targets() {
    return ["loader"];
  }

  static get values() {
    return {
      variantId: Number,
      quantity: Number,
    };
  }

  initialize() {
    this.updateCart = debounce(this.updateCart, 400).bind(this);
  }

  increaseQuantity() {
    this.quantityValue++;
    this.updateCart();
  }

  decreaseQuantity() {
    if (this.quantityValue == 0) return;

    this.quantityValue--;
    this.updateCart();
  }

  updateCart() {
    this.dispatch("updateCart", {
      detail: {
        content: {
          variant_id: this.variantIdValue,
          quantity: this.quantityValue,
        }
      }
    })
    this.toggleLoader();
  }

  toggleLoader() {
    this.loaderTarget.classList.toggle("hidden");
  }

  dataPush({ params: { analytics } }) {
    dataLayer.push({ ecommerce: null });
    dataLayer.push(analytics);
  }
}
