import { Controller as BaseController } from "@hotwired/stimulus"

export class Controller extends BaseController {
  static get targets() {
    return ["container", "leftArrow", "rightArrow"]
  }

  scrollRight() {
    this.containerTarget.scrollLeft += 240
  }

  scrollLeft() {
    this.containerTarget.scrollLeft -= 240
  }
}
