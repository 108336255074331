import { Controller as BaseController } from "@hotwired/stimulus"

export class Controller extends BaseController {
  static get targets() {
    return [ 
      "instructions",
      "btn",
    ]
  }

  open() {
    this.instructionsTarget.classList.remove("right-[-100%]")
    this.instructionsTarget.classList.add("right-0")
    document.body.classList.add("h-full")
    document.body.classList.add("overflow-hidden")
  }

  close() {
    this.instructionsTarget.classList.add("right-[-100%]")
    this.instructionsTarget.classList.remove("right-0")
    document.body.classList.remove("h-full")
    document.body.classList.remove("overflow-hidden")
  }
}
