import { Controller as BaseController } from "@hotwired/stimulus"

export class Controller extends BaseController {
  static get targets() {
    return [
      "paymentForm",
      "payButton",
    ]
  }

  connect() {
  }

  submitPayment() {
    this.redirect_url = this.paymentFormTarget.dataset.redirectUrl;
    this.payButtonTarget.disabled = true;
    Turbo.visit(this.redirect_url);
  }
}
