import { Controller as BaseController } from "@hotwired/stimulus"

export class Controller extends BaseController {
  static get targets() {
    return [ "blocks" ]
  }

  connect() {
    this.styleTitles()
    this.styleText()
    this.styleLinks()
    this.checkArrows()
  }
  
  styleTitles() {
    const headerTwo = this.blocksTarget.querySelectorAll('h2')
    headerTwo.forEach((title) => {
      title.classList.add('font-bold', 'text-base', 'lg:text-lg', 'mb-2', 'mt-4' ,'lg:mt-8', 'lg:flex', 'flex-wrap', 'gap-1', 'items-center', 'cursor-pointer')
    })
    const headerThree = this.blocksTarget.querySelectorAll('h3')
    headerThree.forEach((title) => {
      title.classList.add('font-bold', 'text-sm', 'lg:text-lg', 'mb-2', 'mt-4', 'relative', 'cursor-pointer')
    })
  }
  
  styleText() {
    const texts = this.blocksTarget.querySelectorAll('p')
    texts.forEach((text) => text.classList.add('max-md:text-sm', 'max-w-[95%]'))
  }
  
  styleLinks() {
    const links = this.blocksTarget.querySelectorAll('a')
    links.forEach((link) => link.classList.add('text-emerald-600', 'hover:underline', 'hover:text-emerlad-700'))
  }

  checkArrows() {
    const existingArrows = this.blocksTarget.querySelectorAll("i")
    const existingArrowsArray = Array.from(existingArrows)
    if (existingArrowsArray.length == 0) {
      this.createArrows()
    } else {
        const hiddenParagraphs = this.blocksTarget.querySelectorAll(".hidden-paragraph")
        hiddenParagraphs.forEach((hiddenParagraph) => {
        const title = this.getPreviousSibling(hiddenParagraph, "h2")
        const arrow = title.querySelector(".ph")
        this.collapseExpandhiddenParagraph(arrow, hiddenParagraph)
      })
    }
  }
  
  createArrows() {
    const hiddenParagraphs = this.blocksTarget.querySelectorAll(".hidden-paragraph")
    hiddenParagraphs.forEach((hiddenParagraph) => {
      const title = this.getPreviousSibling(hiddenParagraph, 'h2')
      const arrow = document.createElement("i")
      title.nextElementSibling.classList.add('truncate')
      title.appendChild(arrow)
      arrow.classList.add('ph', 'ph-caret-right', 'lg:text-2xl', 'inline-block', 'transition-all', 'duration-300', 'lg:ml-auto')
      this.collapseExpandhiddenParagraph(arrow, hiddenParagraph)
    })
  }

  collapseExpandhiddenParagraph(arrow, hiddenParagraph) {
    const title = arrow.parentNode
    title.addEventListener("click", (e) => {
      arrow.classList.toggle('rotate-90')
      title.nextElementSibling.classList.toggle('truncate')
      hiddenParagraph.classList.toggle('max-h-96')
    })
  }

  getPreviousSibling(element, selector) {
    let sibling = element.previousElementSibling
    if (!selector) return sibling
    while (sibling) {
      if (sibling.matches(selector)) return sibling
      sibling = sibling.previousElementSibling
    }
  }
}
