import { Controller as BaseController } from "@hotwired/stimulus"
import { turboRequest } from "../../../../../javascript/customer_web/turbo_request";
import { scrollToFrameTop } from "../../../../../javascript/customer_web/scroll_frame";
import { Turbo } from "@hotwired/turbo-rails";

export class Controller extends BaseController {
  static get targets() {
    return [ "sidebar" ]
  }

  connect() {
    const links = this.sidebarTarget.querySelectorAll('a');

    links.forEach(link => {
      link.addEventListener("click", (e) => {
        e.preventDefault();
        this.addFocusStyle(link);
        this.fetchOrRedirect(link);
      });
    });
  }

  addFocusStyle(link) {
    this.removeFocusStyle();
    link.classList.add('text-emerald-700', 'font-bold', 'underline');
  }

  removeFocusStyle() {
    const links = this.sidebarTarget.querySelectorAll('a');

    links.forEach(function(link) {
      link.classList.remove('text-emerald-700', 'font-bold', 'underline');
    });
  }

  fetchOrRedirect(link) {
    if (link.dataset.url != undefined) {
      scrollToFrameTop("taxon_variants_block");

      fetch(this.dataUrlContent(link.dataset.url))
        .then(response => response.text())
        .then(html => {
          Turbo.renderStreamMessage(html);
        });
    } else {
      const turbo_frame = link.dataset.turboFrame;
      if (turbo_frame != undefined) {
        scrollToFrameTop(turbo_frame);

        Turbo.visit(link.href, { action: "replace", frame: turbo_frame });
      } else {
        Turbo.visit(link.href);
      }
    }
  }

  dataUrlContent(url) {
    return turboRequest(url, "GET");
  }
}
