import { Controller as BaseController } from "@hotwired/stimulus"

export class Controller extends BaseController {
  connect() {
    const variantElements =  document.querySelectorAll('.variants_element')

    variantElements.forEach((element) => {
      const anchor = document.getElementById("anchor-" + element.getAttribute('id'))
      if (anchor) {
        anchor.classList.remove('hidden')
      }
    } )
  }
}
