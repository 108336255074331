import { Controller as BaseController } from "@hotwired/stimulus"

export class Controller extends BaseController {
  static get targets() {
    return [ "modal" ]
  }

  connect() {
    document.body.classList.add("h-full");
    document.body.classList.add("overflow-hidden");
  }

  closeModal() {
    this.modalTarget.remove();
    document.body.classList.remove("h-full");
    document.body.classList.remove("overflow-hidden");
  }

  clickOutside(e) {
    if(e.target.classList.contains("h-screen")) {
      this.closeModal()
    }
  }
}
