
import { Controller as BaseController } from "@hotwired/stimulus"

export class Controller extends BaseController {
  static get targets() {
    return [ "analytics" ]
  }

  dataPush() {
    dataLayer.push({ ecommerce: null });
    const data = JSON.parse(this.analyticsTarget.dataset.analytics)
    dataLayer.push(data)
  }
}