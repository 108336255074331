import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";
let debounce = require("lodash/debounce");

export default class extends Controller {
  static get targets() {
    return ["loader"];
  }

  initialize() {
    this.callCart = debounce(this.callCart, 1000).bind(this);
    this.waitingList = [];
    this.working = false;
  }

  update({ detail: { content } }) {
    this.addToWaitingList(content);
    this.callCart();
  }

  callCart() {
    if (this.waitingList.length == 0 || this.working) { return }

    this.working = true;
    this.toggleLoader();
    let params = this.waitingList;
    this.waitingList = [];

    post("/cw/cart", { body: JSON.stringify({ variants: params }), responseKind: "turbo-stream" })
      .then(() => this.working = false)
      .then(() => this.toggleLoader())
      .then(() => this.dismissAllProductLoader(params))
      .then(() => this.callCart());
  }

  addToWaitingList(content) {
    const find = this.waitingList.find((element) => element.variant_id == content.variant_id)
    if (find) {
      this.waitingList = this.waitingList.map((element) => {
        if (element.variant_id == content.variant_id) {
          return content;
        } else {
          return element;
        }
      })
    } else {
      this.waitingList.push(content);
    }
  }

  toggleLoader() {
    this.loaderTarget.classList.toggle("hidden");
  }

  dismissAllProductLoader(params) {
    params.forEach((param) => document.querySelectorAll(`[id='product-card-${param.variant_id}'] .loader`).forEach((element) => element.classList.add('hidden')))
  }
}
