import { Controller as BaseController } from "@hotwired/stimulus"
import { scrollToFrameTop } from "../../../../../javascript/customer_web/scroll_frame";

let debounce = require("lodash/debounce");

export class Controller extends BaseController {
  static get targets() {
    return [ "form", "query" ]
  }

  initialize() {
    this.search = debounce(this.search, 500).bind(this);

    this.formTarget.addEventListener("keydown", (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    });
  }

  search() {
    scrollToFrameTop("taxon_variants_block");

    if (this.queryTarget.value.length > 0) {
      this.formTarget.requestSubmit();
    } else {
      Turbo.visit(
        this.formTarget.dataset.fallbackUrl,
        {
          action: "replace",
          frame: "taxon_variants_block"
        });
    }
  }
}
