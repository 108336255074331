import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['icon'];

  connect() {
    const input = this.element.querySelector('input');
    this.checkInputValue();
    input.dataset.action = `input->${this.identifier}#checkInputValue`;

    if (input.hasAttribute("data-submit-form-target")) {
      input.dataset.action = `${input.dataset.action} input->submit-form#handleInputChange`
    }
  }

  togglePasswordVisibility() {
    const input = this.element.querySelector('input');
    const type = input.getAttribute('type') === 'password' ? 'text' : 'password';
    input.setAttribute('type', type);
    this.iconTarget.classList.toggle('ph-eye-slash');
    this.iconTarget.classList.toggle('ph-eye');
  }

  checkInputValue() {
    const input = this.element.querySelector('input');
    if (input.value === '') {
      this.iconTarget.classList.add('hidden');
    } else {
      this.iconTarget.classList.remove('hidden');
    }
  }
}
