function turboRequest(url, method, body = {}, options = {}) {
  return new Request(url, formatedOptions(method, body, options));
}

function formatedOptions(method, body = {}, options = {}) {
  let result = {
    headers: { "Content-Type": "application/json", "Accept": "text/vnd.turbo-stream.html" },
    method,
    ...options
  }

  if (method !== 'GET' && body != {}) {
    result = { ...result, body: JSON.stringify(body) }
  }

  return result;
}

export { turboRequest }
