import { Controller as BaseController } from "@hotwired/stimulus"

export class Controller extends BaseController {
  static get targets() {
    return [ "payment" ]
  }

  connect() {
    this.fetchPaymentComplete();
  }

  async fetchPaymentComplete() {
    this.paymentCompleteUrl = this.paymentTarget.dataset.completeUrl;

    const response = await fetch(this.paymentCompleteUrl);

    const html = await response.text();
    Turbo.renderStreamMessage(html);
  }
}