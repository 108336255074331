import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submitButton", "requiredInput"];

  connect() {
    this.initialValues = this.requiredInputTargets.map(function (input) {
      if (input.type === 'checkbox') {
        return input.checked;
      } else {
        return input.value.trim();
      }
    });
    this.checkInputValues();
  }

  checkInputValues() {
    const anyModified = this.requiredInputTargets.some((input, index) => this.isModifiedInput(input, index));
    const allValid = this.requiredInputTargets.every((input) => this.isNotEmptyInput(input));
    this.submitButtonTarget.disabled = !(anyModified && allValid);
  }

  isModifiedInput(input, index) {
    const value = input.type === 'checkbox' ? input.checked : input.value.trim();
    const initialValue = this.initialValues[index];
    return value !== initialValue;
  }

  isNotEmptyInput(input) {
    const valueLength = input.value.length;
    const minLength = 1;
    return valueLength >= minLength;
  }

  handleInputChange() {
    this.checkInputValues();
  }
}