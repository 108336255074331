import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    url: String,
    name: String,
    email: String,
  }

  connect() {
    let self = this; // We need to use self to access the Stimulus controller inside the onload function

    let zendeskScriptElement = document.createElement('script');
    zendeskScriptElement.id = "ze-snippet";
    zendeskScriptElement.src = this.urlValue + `&ts=${new Date().getTime()}`;
    zendeskScriptElement.setAttribute('data-turbo-track', 'reload');
    zendeskScriptElement.onload = function () {
      if (self.emailValue && self.nameValue) {
        zE('webWidget', 'prefill', {
          name: { value: `${self.nameValue}`, readOnly: false },
          email: { value: `${self.emailValue}`, readOnly: false }
        });
      }
    };

    this.initWidgetSetting();
    document.head.appendChild(zendeskScriptElement);
  }

  initWidgetSetting() {
    window.zESettings = {
      webWidget: {
        chat: {
          departments: {
            enabled: [
              "J'ai reçu ma commande mais je n'ai pas reçu les bons produits",
              "J'ai reçu ma commande mais je suis déçu de la qualité de certains produits",
              "Je n'ai pas reçu ma commande",
              "Je n'arrive pas à passer ma commande",
              "Je souhaite annuler ma commande",
              "Je souhaite rajouter des instructions de livraison"
            ]
          }
        },
        chat: {
          suppress: true
        },
        offset: {
          mobile: {
            vertical: '50px'
          }
        }
      }
    };
  }
}
