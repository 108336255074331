function scrollToFrameTop(frame_id) {
  const element = document.getElementById(frame_id);
  const rect = element.getBoundingClientRect();
  const positionTop = rect.top + window.scrollY;

  window.scrollTo({
    top: positionTop - 220, // this is set according to the height of the webmobile header
    behavior: 'smooth'
  });
}

export { scrollToFrameTop }
