
import { Controller as BaseController } from "@hotwired/stimulus"

export class Controller extends BaseController {
  static get targets() {
    return [ "analytics" ]
  }

  connect() {
    const analytics = JSON.parse(this.analyticsTarget.dataset.analytics);
    dataLayer.push({ ecommerce: null });
    dataLayer.push(analytics);
  }
}