import { Controller as BaseController } from "@hotwired/stimulus"
import { get, put } from "@rails/request.js";

export class Controller extends BaseController {
  static get targets() {
    return [
      "stripe",
      "payButton",
      "payButtonText",
      "savedCard",
      "newCard",
      "cardForm",
      "memorizeCard",
      "errorComponent",
      "errorMessage",
      "reinsuranceMessage",
      "loader",
    ]
  }

  initialize() {
    this.loggingEnabled = true;
    this.selected_saved_card = null;

    this.displayableErrorCodes = [
      "card_declined",
      "payment_intent_authentication_failure",
      "payment_intent_unexpected_state",
      "payment_intent_payment_attempt_failed",
      "payment_intent_payment_attempt_expired",
      "incorrect_cvc",
      "expired_card",
    ]
  }

  connect() {
    let stripeScriptElement = document.createElement("script");
    stripeScriptElement.src = `https://js.stripe.com/v3/?ts=${new Date().getTime()}`;
    stripeScriptElement.setAttribute('data-turbo-track', 'reload');

    stripeScriptElement.onload = () => {
      this.stripe_publishable_key = this.stripeTarget.dataset.stripePublishableKey;
      this.client_secret          = this.stripeTarget.dataset.clientSecret;
      this.payment_intent_id      = this.stripeTarget.dataset.paymentIntentId;
      this.redirect_url           = this.stripeTarget.dataset.redirectUrl;
      this.validate_url           = this.stripeTarget.dataset.validateUrl;
      this.memorize_card_url      = this.stripeTarget.dataset.memorizeCardUrl;
      this.skip_order_validation  = this.stripeTarget.dataset.skipOrderValidation;

      this.log('Stripe publishable key: ', this.stripe_publishable_key);
      this.log('Client secret: ', this.client_secret);
      this.log('Payment intent id: ', this.payment_intent_id);
      this.log('Redirect url: ', this.redirect_url);
      this.log('Validate url: ', this.validate_url);

      const options = {
        clientSecret: this.client_secret,
        locale: "fr",
        loader: "always",
      };

      this.stripe = Stripe(this.stripe_publishable_key);
      this.stripeElements = this.stripe.elements(options);

      this.paymentElement = this.stripeElements.create('payment',{
        defaultValues: {
          billingDetails: {
            address: {
              country: "fr",
            }
          }
        }
      });

      this.paymentElement.mount('#cardsElement');

      if (this.hasSavedCardTarget) {
        this.selectSavedCard();
      } else {
        this.showCardForm();
      }
    }

    document.head.appendChild(stripeScriptElement);
  }

  async submitPayment(event) {
    event.preventDefault();
    this.disableCheckoutStepsButtons();

    if (this.skip_order_validation) {
      this.pay();
    } else {
      const response = await get(this.validate_url, {
        responseKind: "turbo-stream",
      });

      if (response.ok) {
        this.log('OK response');
        this.pay();
      } else {
        const html = await response.text;
        Turbo.renderStreamMessage(html);
      }
    }
  }

  pay() {
    if (this.selected_saved_card) {
      this.payWithSavedCard();
    } else {
      this.payWithNewCard();
    }
  }

  payWithNewCard() {
    this.log("payWithNewCard");

    this.stripe.confirmPayment({
      elements: this.stripeElements,
      confirmParams: {
        return_url: this.redirect_url
      },
    }).then((result) => {
      const err = result.error;
      if (err) {
        this.log('result.error =>', err);
        this.showError(err);
        this.enableCheckoutStepsButtons();
      }
      this.enableSubmitButton();
    }).catch((err) => {
      this.log('catch err =>', err);
      this.showError(err);
      this.enableSubmitButton();
      this.enableCheckoutStepsButtons();
    })
  }

  payWithSavedCard() {
    this.log("payWithSavedCard");
    this.stripe.confirmCardPayment(this.client_secret, {
      payment_method: this.selected_saved_card,
    }).then((result) => {
      const err = result.error;
      if (err) {
        this.log('error payment', err);
        this.showError(err);
        this.enableSubmitButton();
        this.enableCheckoutStepsButtons();
      } else {
        // intent authorization seems successful
        Turbo.visit(this.redirect_url);
      }
    }).catch((err) => {
      this.log('error payment', err);
      this.showError(err);
      this.enableSubmitButton();
      this.enableCheckoutStepsButtons();
    })
  }

  selectSavedCard() {
    this.log('Select saved card');
    this.hideError();
    let selected_saved_card = document.querySelector('input[name="card_ref"]:checked');
    if (this.hasSavedCardTarget && selected_saved_card) {
      this.log('Selected saved card: ', selected_saved_card.value);
      this.selected_saved_card = selected_saved_card.value;
      this.hideCardForm();
    } else {
      this.log('No saved card');
      this.showCardForm();
    }
  }

  resetSelectedSavedCard() {
    if (this.hasSavedCardTarget) {
      this.log('Reset selected saved card');
      let selected_saved_card = document.querySelector('input[name="card_ref"]:checked');
      selected_saved_card.checked = false;
    }
    this.selected_saved_card = null;
  }

  showCardForm() {
    this.log('Show card form');
    this.hideError();
    this.resetSelectedSavedCard();
    if (this.hasNewCardTarget) {
      this.newCardTarget.classList.add('hidden');
    }
    this.cardFormTarget.classList.remove('hidden');
  }

  hideCardForm() {
    this.log('Hide card form');
    this.newCardTarget.classList.remove('hidden');
    this.cardFormTarget.classList.add('hidden');
    this.memorizeCardTarget.checked = false;
    this.paymentElement.clear();
  }

  async toggleFutureUsage() {
    this.disableSubmitButton()
    this.hideError();
    let value = this.memorizeCardTarget.checked ? 'off_session' : null;
    this.log(`Toggle future usage to ${value}`);

    const response = await put(this.memorize_card_url, { body: JSON.stringify({ value: value }) })
    if (response.ok) {
      this.log(`Done!`);
    } else {
      this.log(`Failed!`);
    }

    this.enableSubmitButton();
  }

  showError(error) {
    if (this.displayableErrorCodes.includes(error.code)) {
      this.reinsuranceMessageTarget.classList.add('md:hidden');
      this.errorComponentTarget.classList.remove('hidden');
      this.errorMessageTarget.innerText = error.message;
    }
  }

  hideError() {
    this.errorComponentTarget.classList.add('hidden');
    this.errorMessageTarget.innerText = '';
    this.reinsuranceMessageTarget.classList.remove('md:hidden');
  }

  disableSubmitButton() {
    this.payButtonTarget.disabled = true;
    this.loaderTarget.classList.remove('hidden');
    this.payButtonTextTarget.innerText = 'Chargement en cours...';
  }

  enableSubmitButton() {
    this.payButtonTarget.disabled = false;
    this.loaderTarget.classList.add('hidden');
    this.payButtonTextTarget.innerText = 'Payer ma commande';
  }

  disableCheckoutStepsButtons() {
    const disablingClasses = ['pointer-events-none', 'border-gray-400', 'text-gray-400'];
    const buttonsToDisable = document.querySelectorAll('.disable-on-payment');

    buttonsToDisable.forEach((button) => {
      button.classList.add(...disablingClasses);
    });
  }

  enableCheckoutStepsButtons() {
    const disablingClasses = ['pointer-events-none', 'border-gray-400', 'text-gray-400'];
    const buttonsToEnable = document.querySelectorAll('.disable-on-payment');

    buttonsToEnable.forEach((button) => {
      button.classList.remove(...disablingClasses);
    });
  }

  log(...args)  {
    if (this.loggingEnabled) {
      console.log(...args);
    }
  }
}
