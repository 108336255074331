import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return [
      "star",
      "shopTagList",
      "shopTag",
      "deliveryTagList",
      "deliveryTag",
      "errorMessage",
    ];
  }

  rateShop({ params: { rating }}) {
    this.starTargets.forEach((elm, index) => {
      if (index + 1 <= rating) {
        elm.classList.add('ph-fill', 'text-emerald-500');
      } else {
        elm.classList.remove('ph-fill', 'text-emerald-500');
      }
    });

    if (this.hasErrorMessageTarget) {
      this.errorMessageTarget.classList.add('hidden');
    }

    if (rating <= 3) {
      this.shopTagListTarget.classList.remove('hidden');
    } else if (rating > 3) {
      this.shopTagListTarget.classList.add('hidden');
      this.shopTagTargets.forEach((elm) => {
        elm.checked = false;
      });
    }
  }

  rateDelivery({ params: { rating }}) {
    if (rating < 5) {
      this.deliveryTagListTarget.classList.remove('hidden');
    } else {
      this.deliveryTagListTarget.classList.add('hidden');
      this.deliveryTagTargets.forEach((elm) => {
        elm.checked = false;
      });
    }
  }
}
