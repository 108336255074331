import { Controller as BaseController } from "@hotwired/stimulus"

export class Controller extends BaseController {
  static get targets() {
    return ["element", "modal", "background"]
  }

  connect() {
    if (!this.shouldDisplayModal()) { return; }
    localStorage.setItem("download_app_popin_display_at", new Date().getTime());

    setTimeout(() => {
      setTimeout(() => {
        this.modalTarget.classList.remove("translate-y-full");
        this.backgroundTarget.classList.add("opacity-50", "h-full");
      }, 250);

      this.elementTarget.classList.remove("hidden");
      document.body.classList.add("h-full");
      document.body.classList.add("overflow-hidden");
    }, 500)
  }

  closeModal() {
    setTimeout(() => {
      this.modalTarget.classList.add("translate-y-full");
      this.backgroundTarget.classList.remove("opacity-50", "h-full");
    }, 250);
    document.body.classList.remove("h-full");
    document.body.classList.remove("overflow-hidden");
  }

  shouldDisplayModal() {
    const downloadAppPopinDisplayAt = localStorage.getItem("download_app_popin_display_at");
    return downloadAppPopinDisplayAt < new Date().getTime() - 86400000;
  }
}
