import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static get targets() {
    return ["slide", "element"];
  }

  connect() {
    this.slideTarget.classList.add("translate-x-full");
    document.body.classList.add("h-full");
    document.body.classList.add("overflow-hidden");
    setTimeout(() => {
      this.slideTarget.classList.remove("translate-x-full");
      this.slideTarget.classList.add(
        "transform",
        "transition",
        "ease-in-out",
        "translate-x-0"
      );
    }, 100);

    this.hideZendeskChat();
  }

  slideOut(e) {
    e.preventDefault();
    this.slideTarget.classList.remove("translate-x-0");
    this.slideTarget.classList.add(
      "transform",
      "transition",
      "ease-in-out",
      "translate-x-full"
    );
    this.elementTarget.classList.remove("animate-fade-in");
    this.elementTarget.classList.add("animate-fade-out");
    document.body.classList.remove("h-full");
    document.body.classList.remove("overflow-hidden");
    setTimeout(() => this.elementTarget.remove(), 350);

    this.showZendeskChat();
  }

  showZendeskChat() {
    if (typeof zE !== 'undefined') {
      zE.show();
    }
  }

  hideZendeskChat() {
    if (typeof zE !== 'undefined') {
      zE.hide();
    }
  }
}
