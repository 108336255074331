import { Controller as BaseController } from "@hotwired/stimulus"
import { turboRequest } from "../../../../../../javascript/customer_web/turbo_request";
import { Turbo } from "@hotwired/turbo-rails";

export class Controller extends BaseController {
  static get targets() {
    return [ "Summary" ]
  }

  openRightSlide(event) {
    event.preventDefault();
    const targetUrl = this.SummaryTarget.dataset.informationsUrl;

    if (targetUrl !== undefined) {
      fetch(this.dataUrlContent(targetUrl))
        .then(response => response.text())
        .then(html => {
          Turbo.renderStreamMessage(html);
        });
    }
  }

  dataUrlContent(url) {
    return turboRequest(url, "GET");
  }
}
