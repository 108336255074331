import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static get targets() {
    return [
      "sidebarMenu",
      "sidebarPlaces",
      "body",
      "cartMask",
      "sidebarMask",
      "placesMask",
      "accountDropdown",
      "cart",
      "hero",
      "navbar",
    ];
  }

  toggleHero(event) {
    let navbarBottom =
      this.navbarTarget.offsetTop + this.navbarTarget.offsetHeight;

    if (event.currentTarget.scrollY > navbarBottom) {
      this.navbarTarget.classList.add("sticky");
      this.heroTarget.classList.add("h-0", "hidden");
      this.heroTarget.classList.remove("h-full", "lg:h-[360px]");
    } else if (event.currentTarget.scrollY == 0) {
      this.navbarTarget.classList.remove("sticky");
      this.heroTarget.classList.remove("h-0", "hidden");
      this.heroTarget.classList.add("h-full", "lg:h-[360px]");
    }
  }

  disconnect() {
    this.closeSidebarMenu();
    this.closeSidebarPlaces();
  }

  openSidebarMenu() {
    this.sidebarMenuTarget.classList.remove("left-[-100%]");
    this.sidebarMenuTarget.classList.add("left-0");
    document.body.classList.add("h-full");
    document.body.classList.add("overflow-hidden");
    this.sidebarMaskTarget.classList.remove("hidden");
  }

  closeSidebarMenu() {
    this.sidebarMenuTarget.classList.add("left-[-100%]");
    this.sidebarMenuTarget.classList.remove("left-0");
    this.sidebarMaskTarget.classList.add("hidden");
    document.body.classList.remove("h-full");
    document.body.classList.remove("overflow-hidden");
  }

  openSidebarPlaces() {
    this.sidebarPlacesTarget.classList.remove("left-[-100%]");
    this.sidebarPlacesTarget.classList.add("left-0");
    document.body.classList.add("h-full");
    document.body.classList.add("overflow-hidden");
    this.placesMaskTarget.classList.remove("hidden");
  }

  closeSidebarPlaces() {
    this.sidebarPlacesTarget.classList.add("left-[-100%]");
    this.sidebarPlacesTarget.classList.remove("left-0");
    document.body.classList.remove("h-full");
    document.body.classList.remove("overflow-hidden");
    this.placesMaskTarget.classList.add("hidden");
  }
}
