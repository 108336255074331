import { Controller } from "@hotwired/stimulus"

let debounce = require("lodash/debounce")

export default class extends Controller {
  static targets = [
    "container",
    "input",
    "suggestionsResults",
    "suggestion",
    "delete",
  ];

  static values = {
    url: String,
  }

  initialize() {
    this.getSuggestions = debounce(this.getSuggestions, 500).bind(this);
  }

  connect() {
    if (this.inputTarget.value === "") {
      this.deleteTarget.classList.add("hidden")
    }
  }

  async getSuggestions() {
    const request = new Request(
      encodeURI(`${this.urlValue}?q=${this.inputTarget.value}`)
    )

    await fetch(request).then(async (response) => {
      this.deleteTarget.classList.remove("hidden")
      const res = await response.json()
      if (res.hasResults) {
        this.suggestionTargets.forEach((suggestion) => suggestion.remove())
        this.containerTarget.classList.remove("hidden")
        this.containerTarget.classList.add("flex")
        this.suggestionsResultsTarget.classList.remove("hidden")
        this.suggestionsResultsTarget.classList.add("flex")
        this.createSuggestionDiv()

        Array.from(res.results).reverse().forEach((element) => {
          this.suggestionsResultsTarget.appendChild(this.createSuggestionDiv(element))
        })
      } else {
        this.containerTarget.classList.add("hidden")
      }
    })
  }

  createSuggestionDiv(element) {
    const suggestionDiv = document.createElement("div")
    suggestionDiv.innerHTML = element
    suggestionDiv.classList.add(
      "cursor-pointer",
      "overflow-hidden",
      "text-sm",
      "text-black",
      "font-normal",
      "hover:font-bold",
      "hover:text-emerald-500",
      "truncate",
      "transition-all",
    )
    suggestionDiv.setAttribute(`data-${this.identifier}-target`, "suggestion")
    suggestionDiv.setAttribute("data-action", `click->${this.identifier}#selectSuggestion`)

    return suggestionDiv
  }

  selectSuggestion(event) {
    this.inputTarget.value = event.target.innerText
    this.containerTarget.classList.add("hidden")
  }

  deleteSuggestion() {
    this.inputTarget.value = ""
    this.inputTarget.focus()
    if (this.inputTarget.value === "") {
      this.containerTarget.classList.add("hidden")
    }
  }

  clickOutside(e) {
    if(this.hasContainerTarget) {
      if(this.element === e.target || this.element.contains(e.target)) return;
      this.containerTarget.classList.add("hidden")
    }
  }
}