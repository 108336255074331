import { Controller as BaseController } from "@hotwired/stimulus"

export class Controller extends BaseController {
  static get targets() {
    return [ "sidebar", "mask" ]
  }

  toggleSidebar() {
    this.sidebarTarget.classList.toggle('left-[-100%]');
    this.sidebarTarget.classList.toggle('left-0');
    document.body.classList.toggle('overflow-hidden');
    document.body.classList.toggle("h-full")
    this.toggleMask();
  }

  toggleMask() {
    this.maskTarget.classList.toggle('hidden');
  }
}
