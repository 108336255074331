import { Controller as BaseController } from "@hotwired/stimulus"

export class Controller extends BaseController {
  static get targets() {
    return [ "caret", "collapsable" ]
  }

  toggleCaret() {
    this.caretTarget.classList.toggle('rotate-180')
    this.collapsableTarget.classList.toggle('visible')
    this.collapsableTarget.classList.toggle('invisible')
    this.collapsableTarget.classList.toggle('translate-y-4')
    this.collapsableTarget.classList.toggle('translate-y-0')
    this.collapsableTarget.classList.toggle('h-0')
    this.collapsableTarget.classList.toggle('h-full')
    this.collapsableTarget.classList.toggle('opacity-0')
    this.collapsableTarget.classList.toggle('opacity-100')
  }
}