import { Controller as BaseController } from "@hotwired/stimulus";
import { turboRequest } from "../../../../../javascript/customer_web/turbo_request";

export class Controller extends BaseController {
  static get targets() {
    return ["element"];
  }

  static get values() {
    return {
      title: String,
      timeout: { type: Number, default: 12000 },
      day_in_millisecond: { type: Number, default: 86400000 },
    };
  }

  async connect() {
    setTimeout(async () => {
      if (
        !this.hasAddress() &&
        this.addressPopinOpenedAt <
          new Date().getTime() - this.dayInMillisecondValue &&
        !this.isAllreadyAModal
      ) {
        localStorage.setItem("address_popin_opened_at", new Date().getTime());
        await fetch(
          turboRequest(`/cw/addresses/modal?title=${this.titleValue}`, "GET"),
        )
          .then((response) => response.text())
          .then((html) => Turbo.renderStreamMessage(html));
      } else {
      }
    }, this.timeoutValue);
  }

  hasAddress() {
    let result = false;
    document
      .querySelectorAll(
        'input[data-customer_web--molecules--address_search-target="input"]',
      )
      .forEach((element) => {
        if (element.value.length > 0) {
          result = true;
        }
      });
    return result;
  }

  get addressPopinOpenedAt() {
    return localStorage.getItem("address_popin_opened_at");
  }

  get isAllreadyAModal() {
    return document.getElementById("modal").childNodes.length > 0;
  }
}
