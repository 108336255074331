import { Controller as BaseController } from "@hotwired/stimulus";
import { turboRequest } from "../../../../../javascript/customer_web/turbo_request";

export class Controller extends BaseController {
  static get targets() {
    return ["analytics", "loader"];
  }

  static get values() {
    return { url: String };
  }

  dataPush() {
    dataLayer.push({ ecommerce: null });
    const data = JSON.parse(this.analyticsTarget.dataset.analytics);
    dataLayer.push(data);
  }

  showSlider(e) {
    e.preventDefault();
    this.toggleLoader();

    fetch(turboRequest(this.url, "GET"))
      .then((response) => response.text())
      .then((html) => Turbo.renderStreamMessage(html))
      .then(() => this.toggleLoader());
  }

  get url() {
    let result = atob(this.urlValue);

    if (window.location.pathname.search(/^\/epiceries\/*/) >= 0) {
      result += "?without_shop=true";
    }

    return result;
  }

  toggleLoader() {
    this.loaderTarget.classList.toggle("hidden");
  }
}
