import { Controller as BaseController } from "@hotwired/stimulus"

export class Controller extends BaseController {
  static get targets() {
    return [ "pushNews" ]
  }

  closePushNews() {
    this.pushNewsTarget.classList.add('hidden');
  }
}
