import { Controller as BaseController } from "@hotwired/stimulus"

export class Controller extends BaseController {
  static get targets() {
    return [ "button", "label", "icon" ]
  }

  static get values() {
    return {
      copy: String,
    };
  }

  copy() {
    const buttonInfo = this.buttonTarget.getBoundingClientRect();
    this.buttonTarget.style.width = `${buttonInfo.width}px`;
    const initialText = this.labelTarget.innerText;
    navigator.clipboard.writeText(this.copyValue);
    this.animateCopy();
    setTimeout(() => {
      this.stopAnimation(initialText);
    }, "3000");
  }

  animateCopy() {
    this.labelTarget.innerText = "COPIÉ";
    this.buttonTarget.disabled = true;
    this.iconTarget.classList.remove("ph-copy");
    this.iconTarget.classList.add("ph-check");
    this.buttonTarget.disabled = true;
  }

  stopAnimation(text) {
    this.labelTarget.innerText = text;
    this.buttonTarget.disabled = false;
    this.iconTarget.classList.add("ph-copy");
    this.iconTarget.classList.remove("ph-check");
    this.buttonTarget.disabled = false;
  }
}