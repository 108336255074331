import { Controller as BaseController } from "@hotwired/stimulus"
import { turboRequest } from "../../../../../javascript/customer_web/turbo_request";
import { Turbo } from "@hotwired/turbo-rails";

export class Controller extends BaseController {
  static get targets() {
    return [ "shopHeader", "description", "seeMoreButton" ]
  }

  connect() {
    if (this.descriptionTarget.offsetHeight < this.descriptionTarget.scrollHeight) {
      this.seeMoreButtonTarget.classList.remove("hidden")
      this.seeMoreButtonTarget.classList.add("flex")
    }

    const dataAnalytics =  this.shopHeaderTarget.dataset.analytics;
    if (dataAnalytics !== undefined) {
      dataLayer.push(JSON.parse(dataAnalytics));
    }
  }

  openRightSlide(event) {
    event.preventDefault();
    const targetUrl = this.shopHeaderTarget.dataset.informationsUrl;

    if (targetUrl !== undefined) {
      fetch(this.dataUrlContent(targetUrl))
        .then(response => response.text())
        .then(html => {
          Turbo.renderStreamMessage(html);
        });
    }
  }

  dataUrlContent(url) {
    return turboRequest(url, "GET");
  }
}
