import { Controller as BaseController } from "@hotwired/stimulus"
import { forEach } from "lodash"

export class Controller extends BaseController {
  static get targets() {
    return [ "lineItems", "seeMoreButton", "seeLessButton", "caret" ]
  }

  connect() {
    var lineItemsCount = this.lineItemsTarget.childElementCount

    if(lineItemsCount > 5) {
      this.seeMoreButtonTarget.classList.remove("hidden")

      this.toggleLineItems()
    }
  }

  toggle() {
    this.seeMoreButtonTarget.classList.toggle('hidden')
    this.seeLessButtonTarget.classList.toggle('hidden')
    this.caretTarget.classList.toggle('rotate-180')
    this.lineItemsTarget.classList.toggle('max-h-full')
    this.toggleLineItems()
  }

  toggleLineItems() {
    forEach(this.lineItemsTarget.children, function(child, i) {
      if(i > 4) {
        child.classList.toggle("hidden")
      }
    })
  }
}
