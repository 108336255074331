import { Controller as BaseController } from "@hotwired/stimulus"
import { turboRequest } from "../../../../../../javascript/customer_web/turbo_request";

export class Controller extends BaseController {
  static get targets() {
    return [ "form" ]
  }

  autocomplete(event) {
    clearTimeout(this.timeout);
    var request = turboRequest(event.target.getAttribute('data-url'), 'POST', { q: event.target.value }, { credentials: 'same-origin' });

    this.timeout = setTimeout(() => {
        fetch(request)
          .then(response => response.text())
          .then(html => {
            Turbo.renderStreamMessage(html)
          });
      }, 500)
  }

  toggleSuggestions() {
    this.suggestions.forEach(elem => elem.classList.toggle('hidden'))
  }

  get suggestions() {
    return document.getElementsByClassName('suggestions')
  }
}
