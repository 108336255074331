import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static get targets() {
    return ["snackbar", "btn"];
  }

  static get values() {
    return {
      timeout: { type: Number, default: 2000 },
    };
  }

  connect() {
    setTimeout(() => {
      this.close();
    }, this.timeoutValue);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  close() {
    this.snackbarTarget.classList.remove("right-8");
    this.snackbarTarget.classList.add("-right-full");
  }
}
