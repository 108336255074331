import { Controller as BaseController } from "@hotwired/stimulus"

export class Controller extends BaseController {
  static get targets() {
    return [ "menu", "currentPage", "caret" ]
  }

  open(){
    this.menuTarget.classList.toggle("h-0");
    this.menuTarget.classList.toggle("h-28");
    this.caretTarget.classList.toggle("rotate-180");
  }
}
