import { Controller as BaseController } from "@hotwired/stimulus"
import { turboRequest } from "../../../../../javascript/customer_web/turbo_request";
import { scrollToFrameTop } from "../../../../../javascript/customer_web/scroll_frame";
import { Turbo } from "@hotwired/turbo-rails";

export class Controller extends BaseController {
  doVisit(event) {
    event.preventDefault();
    const targetUrl = event.srcElement.dataset.url;

    if (targetUrl != undefined) {
      scrollToFrameTop("taxon_variants_block");

      fetch(this.dataUrlContent(targetUrl))
        .then(response => response.text())
        .then(html => {
          Turbo.renderStreamMessage(html);
        });
    }
  }

  dataUrlContent(url) {
    return turboRequest(url, "GET");
  }
}
